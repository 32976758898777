import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  upsStatusFilter: null,
  accessLevelFilter: null,
};

const setting = createSlice({
  name: 'FilterSlice',
  initialState,
  reducers: {
    setUpsStatusFilter: (state, action) => {
      state.upsStatusFilter = action.payload;
    },
    setAccessLevelFilter: (state, action) => {
      state.accessLevelFilter = action.payload;
    },
  },
});

export const { setUpsStatusFilter, setAccessLevelFilter } = setting.actions;

export default setting.reducer;
