import { FormControl, MenuItem, Select } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessLevelFilter } from 'src/reducers/slices/FilterSlice';
import { userTypesEnum } from '../utilities/constant';

const SystemAccessLevelFilter = (props) => {
  const accessLevelFilter = useSelector((state) => state.filters.accessLevelFilter);
  const dispatch = useDispatch();

  const onChange = (event) => {
    const selectedValue = event.target.value;
    const newFilter = {
      column_name: 'type',
      type: 'exact',
      value: selectedValue,
    };
    dispatch(setAccessLevelFilter(newFilter));
    if (props?.context?.setFilters && props.context?.filters) {
      // Replace or add the new filter for upsStatus
      const updatedFilters = props.context.filters.filter((f) => f.column_name !== 'state');
      updatedFilters.push(newFilter);
      props.context.setFilters(cloneDeep(updatedFilters));
    }
  };

  useEffect(() => {
    return () => dispatch(setAccessLevelFilter(null));
  }, [dispatch]);

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        labelId="system-status-label"
        size="small"
        value={accessLevelFilter?.value || ''}
        onChange={onChange}
        sx={{ height: '25px', backgroundColor: 'white' }}
      >
        {userTypesEnum.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemAccessLevelFilter;
